import { Iframe } from "@medudoc/react-sdk";

export const useIframeMessage = () => {
  const PARENT_ORIGIN = "*";

  const postMessage = (message: Iframe.Message) => {
    window.parent.postMessage(message, PARENT_ORIGIN);
  };

  return { postMessage };
};
