import { jwtDecode, JwtPayload } from "jwt-decode";
import React from "react";
import { useSearchParams } from "react-router-dom";
// import { apiClient } from "../../api/client";
import { ApiClient } from "@medudoc/react-sdk";
import { analyticsUser } from "../../analytics/mixpanel";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import AuthContext, { AuthContextValue } from "./auth";

export type User = {
  id: string;
  orgId?: string;
  clientId?: string;
};

export type AccessTokenPayload = JwtPayload & { azp: string };

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [searchParams] = useSearchParams();
  const [accessToken, setAccessToken] = React.useState<string | null>(null);
  const [user, setUser] = React.useState<User | null>(null);
  const [launchId, setLaunchId] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<unknown>(null);

  useEffectOnce(() => {
    const refreshAccessToken = async () => {
      setIsLoading(true);
      const launchId = searchParams.get("launchId");
      const authCode = searchParams.get("authCode");
      if (!launchId || !authCode)
        throw new Error("launchId or authCode not found");

      const baseUrl = import.meta.env.VITE_MEDAPI_BASE_URL;
      const apiClient = new ApiClient({ baseUrl, launchId, authCode });
      const { accessToken } = await apiClient.postToken();
      setAccessToken(accessToken);
      // Note: While using a client access token there is no way of knowing directly which user or org the token is assigned to
      // Therefore we use the launchId to identify the user while leaving the orgName undefined (for now)
      // In the future should we decide to replace the client access token with a user token, we should be able to read the orgName from the token directly
      const accessTokenPayload = jwtDecode<AccessTokenPayload>(accessToken);
      setUser({ id: launchId, clientId: accessTokenPayload.azp });
      setLaunchId(launchId);
    };

    refreshAccessToken()
      // Here we save the error in the local context so it can be made available and handled by other components later
      .catch(setError)
      .finally(() => setIsLoading(false));
  });

  React.useEffect(() => {
    if (user) {
      analyticsUser.identify(user.id, user.orgId, user.clientId);
    }
  }, [user]);

  const getAccessToken = () => accessToken;

  const getLaunchId = () => launchId;

  const value: AuthContextValue = {
    getAccessToken,
    getLaunchId,
    isAuthenticated: !!accessToken,
    isLoading,
    error,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
