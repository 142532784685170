import {
  Api,
  Handover,
  HandoverState,
  MedudocSDKError,
  useNotification,
} from "@medudoc/react-sdk";
import { useNavigate, useParams } from "react-router-dom";
import { curationAnalytics } from "../../analytics/curation";
import { Routes } from "../../constants/routes";
import { useAuth } from "../../context/Auth/auth";
import { useCuration } from "../../context/Curation/curation";
import { useIframeMessage } from "../../hooks/useIframeMessage";

function HandoverPage() {
  const navigate = useNavigate();
  const { concurId } = useParams();
  const { postMessage } = useIframeMessage();
  const { getAccessToken, getLaunchId } = useAuth();
  const [state] = useCuration();
  const { showNotification } = useNotification();
  const onError = (error: MedudocSDKError) => {
    showNotification(error.message);
    postMessage({
      type: "error",
      payload: {
        code: error.code,
      },
    });
  };
  const onDocumentDownload = (d: HandoverState.HandoverDocument) => {
    curationAnalytics.recordDownloadStarted(
      d.id,
      d.isSignatureRequired &&
        d.status === Api.DocumentStatus.READY_FOR_DOWNLOAD,
      state.handover.playlistTitle,
    );
  };
  const onDocumentSign = (d: HandoverState.HandoverDocument) => {
    curationAnalytics.signingStarted(d.id, state.handover.playlistTitle);
  };
  const onOnSiteSigning = (
    handoverDocumentId: HandoverState.HandoverDocumentID,
  ) => {
    curationAnalytics.onSiteSigningSelected(
      handoverDocumentId,
      state.handover.playlistTitle,
    );
  };
  const onRemoteSigning = (
    handoverDocumentId: HandoverState.HandoverDocumentID,
  ) => {
    curationAnalytics.remoteSigningSelected(
      handoverDocumentId,
      state.handover.playlistTitle,
    );
  };

  const onSigningCancel = (
    handoverDocumentId: HandoverState.HandoverDocumentID,
  ) => {
    curationAnalytics.signingCanceled(
      handoverDocumentId,
      state.handover.playlistTitle,
    );
  };

  const onLinkCopied = () => {
    curationAnalytics.linkCopied(state.handover.playlistTitle);
  };
  const onClose = (id: string) => {
    postMessage({ type: "close", payload: { id } });
    curationAnalytics.handoverClosed(state.handover.playlistTitle);
  };
  const onDelete = (id: string) => {
    // Note: For medudoc the handover is cancelled (i.e the data still exists but is no longer available). For the user, it's seen as a 'deletion'
    postMessage({ type: "handover_cancelled", payload: { id } });
    curationAnalytics.handoverCancelled(state.handover.playlistTitle);
    navigate(Routes.INDEX);
  };
  const baseUrl = import.meta.env.VITE_MEDAPI_BASE_URL;
  const dropboxClientId = import.meta.env.VITE_DROPBOX_CLIENT_ID;

  // React.useEffect(() => {
  //   if (!concurId) throw new Error("ConcurId not found");
  //   if (!state.handover.inProgress) {
  //     actions.loadHandover(concurId);
  //     actions.loadVideo(concurId);
  //   }
  // }, []);

  const accessToken = getAccessToken();
  const launchId = getLaunchId();
  if (!accessToken || !launchId) return;

  return (
    <div className="handover-page">
      <Handover
        initialData={state.handover}
        concurId={concurId}
        onDocumentDownload={onDocumentDownload}
        onDocumentSign={onDocumentSign}
        onLinkCopied={onLinkCopied}
        onOnSiteSigning={onOnSiteSigning}
        onRemoteSigning={onRemoteSigning}
        onSigningCancel={onSigningCancel}
        onClose={onClose}
        onDelete={onDelete}
        onError={onError}
        baseUrl={baseUrl}
        accessToken={accessToken}
        launchId={launchId}
        dropboxClientId={dropboxClientId}
        // Todo: In the future we should get this custom label from the Backend
        closeButtonLabel="Zum Termin speichern"
      />
    </div>
  );
}

export default HandoverPage;
