import { FallbackError, MedudocProvider } from "@medudoc/react-sdk";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { initAnalytics } from "./analytics";
import { initErrorTracking } from "./errorTracking";
import "./index.css";

initErrorTracking();
initAnalytics();

const renderFallback: Sentry.FallbackRender = ({ eventId }) => {
  return <FallbackError eventId={eventId}></FallbackError>;
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={renderFallback}>
      <MedudocProvider>
        <App />
      </MedudocProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
