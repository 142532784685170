import { Api, HandoverState } from "@medudoc/react-sdk";
import React from "react";
import { Action, ActionTypes } from "../state/curation";

export const setLoadedHandover =
  (dispatch: React.Dispatch<Action>) => (handover: Api.Handover) => {
    const { documents } = handover;

    const downloads: HandoverState.HandoverDocument[] = documents.map((d) => ({
      id: HandoverState.HandoverDocumentID[d.type],
      file: {
        name: d.file.name,
        data: d.file.data,
      },
      downloaded: false,
      status: d.status,
      isSignatureRequired: d.signable,
    }));

    dispatch({
      type: ActionTypes.SET_LOADED_HANDOVER,
      handover: {
        id: handover?.id,
        patientUrl: handover?.patientUrl ?? "",
        playlistTitle: handover?.procedureName ?? "",
        documents: downloads ?? [],
        inProgress: true,
        videoWatched: !!handover?.seenAt,
        signatureProvider: handover?.signatureProvider,
      },
    });
  };
