export enum EventCategories {
  CURATION = "Curation",
}

export enum CurationEvents {
  STARTED_NEW = "started new",
  HANDOVER_STARTED = "handover started",
  RECORD_DOWNLOAD_STARTED = "record download started",
  SIGNING_STARTED = "signing started",
  SIGNING_CANCELED = "signing canceled",
  ON_SITE_SIGNING_SELECTED = "on-site signing selected",
  REMOTE_SIGNING_SELECTED = "remote signing selected",
  LINK_COPIED = "link copied",
  // hcp-embed only events
  HANDOVER_CANCELLED = "handover cancelled",
  HANDOVER_CLOSED = "handover closed",
}

export type AnalyticsEvent = {
  category: EventCategories;
  name: CurationEvents;
  [k: string]: unknown;
};
