import type { EffectCallback } from "react";
import { useEffect, useRef } from "react";

// React 18 strict mode causes effects to fire twice in development mode. This custom hook prevents the action inside the effect to run more than once
// Ref: https://taig.medium.com/prevent-react-from-triggering-useeffect-twice-307a475714d7
export function useEffectOnce(effect: EffectCallback) {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      effect();
    }
  });
}
