import React from "react";
import * as actions from "../../model/actions";
import {
  Action,
  curationReducer,
  curationSelectors,
  CurationState,
  initialState,
} from "../../model/state/curation";
import CurationContext, {
  CurationContextValue,
  DispatchActions,
  StateSelectors,
} from "./curation";

const applyDispatchToActions = (dispatch: React.Dispatch<Action>) =>
  Object.fromEntries(
    Object.entries(actions).map(([k, fn]) => [k, fn(dispatch)]),
  ) as DispatchActions;

const applyStateToSelectors = (state: CurationState) =>
  Object.fromEntries(
    Object.entries(curationSelectors).map(([k, fn]) => [k, fn(state)]),
  ) as StateSelectors;

export const CurationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(curationReducer, initialState);
  const actions = React.useMemo(() => applyDispatchToActions(dispatch), []);
  const selectors = applyStateToSelectors(state);

  const value: CurationContextValue = [state, actions, selectors];

  return (
    <CurationContext.Provider value={value}>
      {children}
    </CurationContext.Provider>
  );
};
