import { v4 as uuidv4 } from "uuid";
import { CurationEvents, EventCategories } from "./events";
import { analyticsUser, trackEvent } from "./mixpanel";

const generateId = () => uuidv4();

class CurationAnalytics {
  private curationId: string = "";
  private changesFromDefaultScript: Set<string> = new Set();

  private reset() {
    this.curationId = generateId();
    this.changesFromDefaultScript = new Set();
  }

  startedNew() {
    this.reset();

    trackEvent({
      category: EventCategories.CURATION,
      name: CurationEvents.STARTED_NEW,
      curationId: this.curationId,
    });
  }

  handoverStarted(procedureName: string, selectedDataParams: string[]) {
    /**
     * TODO: sync curation reset/increment
     * It is now possible that the total of the user property `curations completed`
     * will be greater than the number of curationIds (i.e. distinct curation funnels).
     * A curationId is not reset until the `startedNew` event,
     * but it is possible for the handover to be started multiple time before then.
     */
    analyticsUser.incrementCurations();

    trackEvent({
      category: EventCategories.CURATION,
      name: CurationEvents.HANDOVER_STARTED,
      curationId: this.curationId,
      procedureName,
      changesFromDefault: this.changesFromDefaultScript.size,
      namesOfChangesFromDefault: Array.from(
        this.changesFromDefaultScript.values(),
      ),
      selectedDataParams,
    });
  }

  recordDownloadStarted(
    docTitle: string,
    signed: boolean,
    procedureName: string,
  ) {
    trackEvent({
      category: EventCategories.CURATION,
      name: CurationEvents.RECORD_DOWNLOAD_STARTED,
      curationId: this.curationId,
      docTitle,
      procedureName,
      changesFromDefault: this.changesFromDefaultScript.size,
      namesOfChangesFromDefault: Array.from(
        this.changesFromDefaultScript.values(),
      ),
      signed,
    });
  }

  signingStarted(docTitle: string, procedureName: string) {
    trackEvent({
      category: EventCategories.CURATION,
      name: CurationEvents.SIGNING_STARTED,
      curationId: this.curationId,
      docTitle,
      procedureName,
      changesFromDefault: this.changesFromDefaultScript.size,
      namesOfChangesFromDefault: Array.from(
        this.changesFromDefaultScript.values(),
      ),
    });
  }

  signingCanceled(docTitle: string, procedureName: string) {
    trackEvent({
      category: EventCategories.CURATION,
      name: CurationEvents.SIGNING_CANCELED,
      curationId: this.curationId,
      docTitle,
      procedureName,
    });
  }

  onSiteSigningSelected(docTitle: string, procedureName: string) {
    trackEvent({
      category: EventCategories.CURATION,
      name: CurationEvents.ON_SITE_SIGNING_SELECTED,
      curationId: this.curationId,
      docTitle,
      procedureName,
    });
  }

  remoteSigningSelected(docTitle: string, procedureName: string) {
    trackEvent({
      category: EventCategories.CURATION,
      name: CurationEvents.REMOTE_SIGNING_SELECTED,
      curationId: this.curationId,
      docTitle,
      procedureName,
    });
  }

  linkCopied = (procedureName: string) => {
    trackEvent({
      category: EventCategories.CURATION,
      name: CurationEvents.LINK_COPIED,
      curationId: this.curationId,
      procedureName,
    });
  };

  handoverCancelled(procedureName: string) {
    trackEvent({
      category: EventCategories.CURATION,
      name: CurationEvents.HANDOVER_CANCELLED,
      curationId: this.curationId,
      procedureName,
    });
  }

  handoverClosed(procedureName: string) {
    trackEvent({
      category: EventCategories.CURATION,
      name: CurationEvents.HANDOVER_CLOSED,
      curationId: this.curationId,
      procedureName,
    });
  }
}

export const curationAnalytics = new CurationAnalytics();
