import React from "react";
import { useAuth } from "../../context/Auth/auth";
import { useIframeMessage } from "../../hooks/useIframeMessage";

type RequireAuthProps = { children: React.ReactNode };

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { error } = useAuth();
  const { postMessage } = useIframeMessage();

  React.useEffect(() => {
    if (error) {
      postMessage({
        type: "error",
        payload: {
          code: "ERR_AUTHENTICATION_FAILED",
        },
      });
      // An authentication error should never happen and it can only result from a programming error or server downtime.
      // The re-thrown error will be caught by `<Sentry.ErrorBoundary>` which renders an error page with the respective sentry error event id.
      // This approach is better than showing a standard toast message because the toast message encourages the user to engage with the app further.
      // The sentry error page on the other hand encourages the user to report the error (while the error itself is also sent to Sentry).
      throw error;
    }
  }, [error, postMessage]);

  return <>{children}</>;
};

export default RequireAuth;
