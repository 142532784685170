import { HandoverState } from "@medudoc/react-sdk";

export type CurationState = {
  handover: HandoverState.Handover;
  video: HandoverState.HandoverVideo;
};

export const initialState: CurationState = {
  handover: {
    documents: [],
    patientUrl: "",
    id: "",
    playlistTitle: "",
    inProgress: false,
    videoWatched: false,
  },
  video: {
    url: "",
  },
};

export enum ActionTypes {
  SET_LOADED_HANDOVER = "SET_LOADED_HANDOVER",
  SET_LOADED_VIDEO = "SET_LOADED_VIDEO",
}

export type Action =
  | { type: ActionTypes.SET_LOADED_HANDOVER; handover: HandoverState.Handover }
  | { type: ActionTypes.SET_LOADED_VIDEO; video: HandoverState.HandoverVideo };

export function curationReducer(
  state: CurationState,
  action: Action,
): CurationState {
  switch (action.type) {
    case ActionTypes.SET_LOADED_HANDOVER:
      return setLoadedHandover(state, action.handover);
    case ActionTypes.SET_LOADED_VIDEO:
      return setLoadedVideo(state, action.video);
    default: {
      throw new Error(`Unhandled action: ${JSON.stringify(action)}`);
    }
  }
}

function setLoadedHandover(
  state: CurationState,
  handover: HandoverState.Handover,
): CurationState {
  return {
    ...state,
    handover,
  };
}

function setLoadedVideo(
  state: CurationState,
  video: HandoverState.HandoverVideo,
): CurationState {
  return {
    ...state,
    video,
  };
}

export type CurationSelectors = {
  getHandoverId: (state: CurationState) => () => string;
};

// Selectors
function getHandoverId(state: CurationState) {
  return (): string => {
    const { handover } = state;
    return handover.id;
  };
}

export const curationSelectors: CurationSelectors = {
  getHandoverId,
};
