import React from "react";

export interface AuthContextValue {
  isAuthenticated: boolean;
  isLoading: boolean;
  error?: unknown;
  getAccessToken: () => string | null;
  getLaunchId: () => string | null;
}

const initialContextValue: AuthContextValue = {
  isAuthenticated: false,
  isLoading: false,
  error: undefined,
  getAccessToken: () => null,
  getLaunchId: () => null,
};

const AuthContext = React.createContext<AuthContextValue>(initialContextValue);

AuthContext.displayName = "AuthContext";

export const useAuth = (): AuthContextValue => {
  const authContextValue = React.useContext(AuthContext);

  if (!authContextValue) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return authContextValue;
};

export default AuthContext;
