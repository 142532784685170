import React from "react";
import * as actions from "../../model/actions";
import { curationSelectors, CurationState } from "../../model/state/curation";

export type DispatchActions = {
  [K in keyof typeof actions]: ReturnType<(typeof actions)[K]>;
};

export type StateSelectors = {
  [K in keyof typeof curationSelectors]: ReturnType<
    (typeof curationSelectors)[K]
  >;
};

export type CurationContextValue =
  | [CurationState, DispatchActions, StateSelectors]
  | undefined;

const CurationContext = React.createContext<CurationContextValue>(undefined);

CurationContext.displayName = "CurationContext";

export const useCuration = () => {
  const curationContext = React.useContext(CurationContext)!;

  if (!curationContext) {
    throw new Error("useCuration must be used within a CurationProvider");
  }

  return curationContext;
};

export default CurationContext;
