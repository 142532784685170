import {
  Api,
  CurationForm,
  FormSelection,
  MedudocSDKError,
  useNotification,
} from "@medudoc/react-sdk";
import { useNavigate } from "react-router";
import { curationAnalytics } from "../../analytics/curation";
import { analyticsUser } from "../../analytics/mixpanel";
import { Routes } from "../../constants/routes";
import { useAuth } from "../../context/Auth/auth";
import { useCuration } from "../../context/Curation/curation";
import { useIframeMessage } from "../../hooks/useIframeMessage";

function CurationPage() {
  const navigate = useNavigate();
  const [, actions] = useCuration();
  const { getAccessToken, getLaunchId } = useAuth();
  const { postMessage } = useIframeMessage();
  const { showNotification } = useNotification();
  const onError = (error: MedudocSDKError) => {
    showNotification(error.message);
    postMessage({
      type: "error",
      payload: {
        code: error.code,
      },
    });
  };
  const onConfigLoading = (config: Api.ContentConfig) => {
    analyticsUser.trackMedicalDomains(
      config.medicalDomains.map(({ name }) => name),
    );
    curationAnalytics.startedNew();
  };
  const onHandover = (handover: Api.Handover) => {
    actions.setLoadedHandover(handover);
    postMessage({
      type: "handover_created",
      payload: {
        id: handover.id,
      },
    });
    const concurId = handover.patientUrl.split("/").pop() ?? "";
    const handoverRoute = Routes.HANDOVER.replace(":concurId", concurId);
    navigate(handoverRoute);
  };
  const onSubmit = (formSelection: FormSelection) => {
    const selectedDataParams = Array.from(
      formSelection.dataParams,
      ([paramGroup, param]) => `${paramGroup.name}:${param.name}`,
    );
    if (formSelection.sexParam) {
      selectedDataParams.push(`Geschlecht:${formSelection.sexParam?.name}`);
      // selectedDataParams.push(`${t("sex")}:${formSelection.sexParam?.name}`);
    }
    if (!formSelection.procedure?.name)
      throw new Error("procedure name not found in form selection");
    curationAnalytics.handoverStarted(
      formSelection.procedure?.name,
      selectedDataParams,
    );
  };
  const baseUrl = import.meta.env.VITE_MEDAPI_BASE_URL;

  const accessToken = getAccessToken();
  const launchId = getLaunchId();
  if (!accessToken || !launchId) return;

  return (
    <div className="curation-page">
      <CurationForm
        onSubmit={onSubmit}
        onConfigLoading={onConfigLoading}
        onError={onError}
        onHandover={onHandover}
        baseUrl={baseUrl}
        launchId={launchId}
        accessToken={accessToken}
      />
    </div>
  );
}

export default CurationPage;
