import mixpanel from "mixpanel-browser";
import { AnalyticsEvent } from "./events";

const getCookies = (): Map<string, string> => {
  return document.cookie.split("; ").reduce((cookies, cookie) => {
    const [key, value] = cookie.split("=");
    return cookies.set(key, value);
  }, new Map<string, string>());
};

const getCookie = (key: string): string | undefined => getCookies().get(key);

const isQASession = () => getCookie("qa_session") === "true";

const USE_ANALYTICS: boolean =
  process.env.NODE_ENV !== "test" &&
  !!import.meta.env.VITE_MIXPANEL_TOKEN &&
  !isQASession();

const MIXPANEL_TOKEN: string = import.meta.env.VITE_MIXPANEL_TOKEN || "";

export const initMixpanel = () => {
  if (!USE_ANALYTICS) {
    return;
  }

  mixpanel.init(MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV === "development",
    api_host: "https://api-eu.mixpanel.com",
    ignore_dnt: process.env.NODE_ENV === "development",
  });
};

const mixpanelEvent = ({
  category,
  name,
  ...properties
}: AnalyticsEvent): [string, Record<string, unknown>] => {
  return [
    `${category}: ${name}`,
    { medudocApplication: "hcp-embed", ...properties },
  ];
};

export const trackEvent = (event: AnalyticsEvent) => {
  if (!USE_ANALYTICS) {
    return;
  }

  if (process.env.NODE_ENV === "development") {
    console.log("MIXPANEL EVENT: ", event);
  }

  mixpanel.track(...mixpanelEvent(event));
};

enum UserProperties {
  CURATIONS_COMPLETED = "curations completed",
  MEDICAL_DOMAINS = "medical domains",
  ORGANIZATION_NAME = "organization name",
  CLIENT = "client",
}

const identify = (
  userId: string,
  organizationName?: string,
  clientId?: string,
) => {
  if (!USE_ANALYTICS) {
    return;
  }

  /**
   * A user might belong to multiple auth0 organizations (e.g. demo & medudoc).
   * In mixpanel we will identify a distinct user for each organization.
   * This makes it possible to accurately identify events originating from
   * each organization.
   */

  const mixpanelUserId = organizationName
    ? `${organizationName}-${userId}`
    : userId;
  const isEligibleForTracking = (orgName: string) =>
    !orgName.startsWith("demo");

  if (organizationName && !isEligibleForTracking(organizationName)) {
    mixpanel.set_config({ opt_out_tracking_cookie_prefix: mixpanelUserId });
    mixpanel.opt_out_tracking({ cookie_prefix: mixpanelUserId });
  }

  mixpanel.identify(mixpanelUserId);
  mixpanel.people.set_once(UserProperties.ORGANIZATION_NAME, organizationName);
  mixpanel.people.set_once(UserProperties.CLIENT, clientId);
};

const incrementCurations = () => {
  if (!USE_ANALYTICS) {
    return;
  }

  mixpanel.people.increment(UserProperties.CURATIONS_COMPLETED);
};

const trackMedicalDomains = (medicalDomains: string[]) => {
  if (!USE_ANALYTICS) {
    return;
  }

  mixpanel.people.union(UserProperties.MEDICAL_DOMAINS, medicalDomains);
};

export const analyticsUser = {
  identify,
  incrementCurations,
  trackMedicalDomains,
};
