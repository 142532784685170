import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

// import i18n from "i18next";

const reportDialogOptions: Sentry.ReportDialogOptions = {
  lang: "de",
  // lang: i18n.language || "de",
};

export const logMessage = (message: string) => {
  Sentry.captureMessage(message);
};

export const showReportDialog = (event: Sentry.Event) => {
  const containsUnhandledException =
    event.exception?.values?.some(({ mechanism }) => {
      if (mechanism?.handled === false) {
        // only explicitly unhandled exceptions will be considered unhandled
        return true;
      }

      return false;
    }) || false;

  if (containsUnhandledException) {
    Sentry.showReportDialog({
      eventId: event.event_id,
      ...reportDialogOptions,
    });
  }
};

export const initSentry = () => {
  const MEDAPI_HOST = new URL(import.meta.env.VITE_MEDAPI_BASE_URL).host;

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracePropagationTargets: ["localhost", /^\//, MEDAPI_HOST],
    tracesSampleRate: 1.0,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
    beforeSend(event) {
      showReportDialog(event);
      return event;
    },
  });
};
