import { Api } from "@medudoc/react-sdk";
import React from "react";
import { Action, ActionTypes } from "../state/curation";

export const setLoadedVideo =
  (dispatch: React.Dispatch<Action>) => (video: Api.Video) => {
    dispatch({
      type: ActionTypes.SET_LOADED_VIDEO,
      video: {
        url: video.url,
      },
    });
  };
