import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import CurationPage from "./components/CurationPage/CurationPage";
import HandoverPage from "./components/HandoverPage/HandoverPage";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import { Routes as AppRoutes } from "./constants/routes";
import { AuthProvider } from "./context/Auth/AuthProvider";
import { CurationProvider } from "./context/Curation/CurationProvider";
import { useEffectOnce } from "./hooks/useEffectOnce";
import { useIframeMessage } from "./hooks/useIframeMessage";
import "./index.css";

export function App() {
  const { postMessage } = useIframeMessage();

  useEffectOnce(() => {
    postMessage({ type: "open" });
  });

  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route
              path={AppRoutes.INDEX}
              element={
                <RequireAuth>
                  <CurationProvider>
                    <CurationPage />
                  </CurationProvider>
                </RequireAuth>
              }
            />
            <Route
              path={AppRoutes.HANDOVER}
              element={
                <RequireAuth>
                  <CurationProvider>
                    <HandoverPage />
                  </CurationProvider>
                </RequireAuth>
              }
            />
            <Route
              path="*"
              element={<Navigate to={{ pathname: AppRoutes.INDEX }} />}
            />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
